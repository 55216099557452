import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Section from '../components/section';
import Frame from '../components/frame';

import { dimensions } from '../shared/styles';
import content from '../data/static-content.js';

import packageJson from '../../package.json';

/* ---------------------------------------------- */
/* ---------------------------------------------- */

const MainSection = styled(Section).attrs(props => ({
  wrapperStyle : {
    height : '100%'
  }
}))`
  height : 100%;
  padding-top : ${dimensions.headerHeight};
  margin-bottom : 15rem;
  position : relative;
  display : flex;
  flex-direction : row;
  align-items : center;
  justify-content : flex-start;

  font-size : 1.6rem;
`;

const Version = styled.div`
  position : fixed;
  bottom : 0px;
  left : 0px;
  font-size : 8px;
  color : #AAA;
  padding : 0 5px 5px 0;
`

const MainFrame = styled(Frame)`
  max-width : 95%;
  margin : ${dimensions.headerHeight};

  @media screen and (max-width : 425px) {
    margin : ${dimensions.headerHeight} 4rem;
  }
`;


const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Appunto" />
      <MainSection>
        <MainFrame tr bl="pink" contentStyle={{paddingBottom : '2rem'}}>
          <h1>{content.mentions.title}</h1>
          <h3>{content.mentions.titleEditeur}</h3>
          <table>
            <tbody>
              {
                Object.entries(content.mentions.editeur).map(
                  ([key, value], i) => <tr key={i}><td>{key}</td><td>{value}</td></tr>
                )
              }
            </tbody>
          </table>
          <h3>{content.mentions.titleDirPub}</h3>
            <table>
              <tbody>
                {
                  Object.entries(content.mentions.dirPub).map(
                    ([key, value], i) => <tr key={i}><td>{key}</td><td>{value}</td></tr>
                  )
                }
              </tbody>
            </table>
          <h3>{content.mentions.titleHosting}</h3>
            <table>
              <tbody>
                {
                  Object.entries(content.mentions.hosting).map(
                    ([key, value], i) => <tr key={i}><td>{key}</td><td>{value}</td></tr>
                  )
                }
              </tbody>
            </table>
        </MainFrame>
      </MainSection>
      <Version>{packageJson.version}</Version>
    </Layout>
  );
}


export default IndexPage
